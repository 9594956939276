import styled from 'styled-components';
import { Box, Container } from '@components/layout/Grid';
import Icon from '@components/Icon';
import { StartOverviewQuery } from '@gql/generated';
import { Title } from '@ecosystems/landing_pages/hero/components';

const Grid = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  margin: 20px auto 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    margin-top: 40px;
    grid-template-columns: repeat(
      auto-fill,
      minmax(clamp(180px, 30%, 400px), 1fr)
    );
    gap: 20px;
  }
`;

const Card = styled.div`
  transform: scale(1);
  transition: transform 200ms ease-in-out;

  :hover {
    transform: scale(1.02);
  }
`;

// const Title = styled.h2`
//   max-width: 620px;
//   color: #243240;
//   font-size: 22px;
//   font-weight: bold;
//   line-height: 34px;
//   text-align: center;
//   margin: 0;

//   @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
//     font-size: 28px;
//   }
// `;

const CardImage = styled.img`
  width: 100%;
  border-radius: 4px;
  background-color: #cecece;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    width: 100%;
    aspect-ratio: 1.5;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const CardTitle = styled.h3`
  color: #243240;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 10px;

  i {
    display: inline-block;
    margin-left: 5px;
    transform: rotateY(180deg);
  }
`;

const CardDesc = styled.p`
  color: #242424;
  font-size: 14px;
  line-height: 24px;
`;

const Feature = ({ className = '', title, desc, image }) => {
  return (
    <Card className={className}>
      <CardImage src={image} />
      <Box pb={10}>
        <Title className="flex items-center" as="h3">
          {title}
          <Icon
            className="rotate-180 ml-3 -mb-1"
            name="ygb-icon-Back-arrow-ic"
            fontSize="14px"
          />
        </Title>
        <CardDesc>{desc}</CardDesc>
      </Box>
    </Card>
  );
};

const Anchor = styled.a`
  text-decoration: none;
`;

type Props = {
  data: StartOverviewQuery['startOverview']['otherFeaturesSection'];
};

export const OtherYogobeFeaturesSection = (props: Props) => {
  const {
    data: { title, otherFeatures },
  } = props;

  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      my={[20, 20, 40]}
    >
      <Title as="h2" className="text-center">
        {title}
      </Title>
      <Grid>
        {otherFeatures.map((item, i) => {
          return (
            <Anchor key={i} href={item.url}>
              <Feature
                title={item.title}
                desc={item.description}
                image={item.imageUrl}
              />
            </Anchor>
          );
        })}
      </Grid>
    </Container>
  );
};
