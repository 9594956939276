import { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { Trans } from '@lingui/react';
import { Box, Container, Flex } from '@components/layout/Grid';
import Icon from '@components/Icon';
import { OtherTypeKind, StartOverviewQuery } from '@gql/generated';
import LanguageContext from '@lib/contexts/languageContext';
import { Button } from '@components/ButtonV2';
import { Title } from '@ecosystems/landing_pages/hero/components';
import { UserContext } from '@lib/contexts/UserProvider';

const Grid = styled(Box)`
  --margin-right: 12px;
  /*  */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.viewports.mobile}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Anchor = styled(Link)`
  text-decoration: none;
`;

// const Title = styled.h2`
//   max-width: 620px;
//   color: #243240;
//   font-size: 22px;
//   font-weight: bold;
//   line-height: 34px;
//   text-align: center;
//   margin: 0 0 15px;

//   @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
//     font-size: 28px;
//   }
// `;

const SubTitle = styled.p`
  max-width: 620px;
  color: #243240;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 18px;
  }
`;

const Card = styled.div`
  transform: scale(1);
  transition: transform 200ms ease-in-out;

  :hover {
    transform: scale(1.02);
  }
`;

const CardImage = styled.img`
  width: 100%;
  aspect-ratio: 1.5;
  border-radius: 4px;
  background-color: #cecece;
`;

const CardTitle = styled.h3`
  color: #243240;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 10px;

  i {
    display: inline-block;
    margin-left: 5px;
    transform: rotateY(180deg);
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 18px;
  }
`;

const CardDesc = styled.p`
  color: #242424;
  font-size: 13px;
  line-height: 1.4;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 15px;
  }
`;

const PricingButton = styled(Button)`
  font-size: 18px;
  border-bottom: 1px solid currentColor;
  border-radius: 0px;
  padding-bottom: 2px;
`;

const Feature = ({ className = '', title, desc, image }) => {
  return (
    <Card className={className}>
      <CardImage src={image} />
      <Box pt={20} pb={10}>
        <CardTitle>
          {title} <Icon name="ygb-icon-Back-arrow-ic" fontSize="10px" />
        </CardTitle>
        <CardDesc>{desc}</CardDesc>
      </Box>
    </Card>
  );
};

const LINKS = {
  [OtherTypeKind['Videos']]: 'videos/genres/all',
  [OtherTypeKind['Programs']]: 'programs',
  [OtherTypeKind['Live']]: 'live',
  [OtherTypeKind['Challenges']]: 'challenge',
  [OtherTypeKind['Playlists']]: 'playlists',
  [OtherTypeKind['Bundles']]: 'needs-and-themes',
} as const;

type Props = {
  data: StartOverviewQuery['startOverview']['videoLibrarySection'];
};

const ORDER = [
  OtherTypeKind['Videos'],
  OtherTypeKind['Challenges'],
  OtherTypeKind['Live'],
  OtherTypeKind['Playlists'],
  OtherTypeKind['Programs'],
  OtherTypeKind['Bundles'],
];

export const YogobeFeaturesSection = (props: Props) => {
  const [currentUser] = useContext(UserContext);
  const lang = useContext(LanguageContext);
  const {
    data: { title, description, videosLibrary },
  } = props;

  const showTrialBtn = !!currentUser?.trial || !currentUser?.id;

  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      pt={20}
      pb={40}
    >
      <Title as="h2" className="text-center pb-2">
        {title}
      </Title>
      <SubTitle>{description}</SubTitle>
      <Grid mt={[20, 20, 40]} mb={20}>
        {[...videosLibrary]
          .sort(
            (a, b) => ORDER.indexOf(a.otherType) - ORDER.indexOf(b.otherType)
          )
          .map((item, i) => {
            const href = LINKS[item.otherType]
              ? `/${lang}/${LINKS[item.otherType]}`
              : '#';
            return (
              <Anchor key={i} href={href}>
                <Feature
                  title={item.title}
                  desc={item.description}
                  image={item.imageUrl}
                />
              </Anchor>
            );
          })}
      </Grid>
      <div className="flex gap-4 mt-2.5 md:mt-5">
        {showTrialBtn ? (
          <Button
            href={
              currentUser?.trial
                ? `/${lang}/get-started/trial`
                : `/${lang}/register/account`
            }
            rounded
          >
            <Trans id="homepage.features_section.trial_btn" />
          </Button>
        ) : null}
        <Button href={`/${lang}/pricing`} outline={showTrialBtn} rounded>
          <Trans id="homepage.features_section.our_pricing_btn" />
        </Button>
      </div>
    </Container>
  );
};
