import { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { ArrowRightIcon } from '@assets/index';
import { Button } from '@components/ButtonV2';
import LanguageContext from '@lib/contexts/languageContext';
import BundleCard from '@organisms/cards/BundleCard';
import GenericSwipeSection from '@organisms/sections/GenericSwipeSection';
import { Box } from '@components/layout/Grid';
import { Title } from '@ecosystems/landing_pages/hero/components';

const Section = styled(Box)`
  max-width: 1280px;
  width: 100%;
  overflow: hidden;

  &:empty {
    display: none;
  }

  .swiper {
    overflow: unset;
  }
`;

const ViewAllButton = styled(Button)`
  font-size: 14px;
  padding: 0;

  svg {
    width: 16px;
    margin-left: 5px;
  }
`;

export const BundlesSection = ({ bundles }) => {
  const lang = useContext(LanguageContext);

  return (
    <Section my={[20, 20, 40]} mx="auto">
      <div className="px-4">
        <div className="flex flex-wrap gap-4 justify-between items-center pb-4">
          <Title as="h2" className="text-xl font-medium !m-0">
            <Trans id="homepage.bundles_section.title" />
          </Title>
          <ViewAllButton
            href={`/${lang}/needs-and-themes`}
            appearance="dark"
            link
          >
            <span>
              <Trans
                id="homepage.bundles_section.view_all"
                message="View all bundles"
              />
            </span>
            <ArrowRightIcon name="ygb-icon-Back-arrow-ic" fontSize="14px" />
          </ViewAllButton>
        </div>
        <div className="flex flex-col gap-3 md:flex-row">
          {bundles?.map(item => (
            <BundleCard key={item.slug} bundle={item} />
          ))}
        </div>
      </div>
    </Section>
  );
};
