import styled, { useTheme } from 'styled-components';
import { Trans } from '@lingui/react';
import { useMedia } from 'use-media';
import { Button } from '@components/ButtonV2';
import { HeroSection } from '@gql/generated';
import { useContext, useEffect, useState } from 'react';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import RightArrowIcon from '@assets/ArrowRightIcon';
import { Title } from '@ecosystems/landing_pages/hero/components';

const Section = styled.section<{ bg: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  // 76px for header
  min-height: calc(100vh - 76px);
  padding: 10% max(calc((100vw - 1240px) / 2), 20px) 0;
  margin-bottom: 30px;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #242424;
    z-index: 1;
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    min-height: 80vh;
    max-height: 640px;
    padding: 10% max(calc((100vw - 1240px) / 2), 20px);
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  max-width: max(580px, 60%);
  z-index: 1;
`;

const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4em;
  text-wrap: balance;
  padding: 20px 0;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 24px;
    max-width: 80%;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Disclaimer = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 0 10px;
  opacity: 0.8;
`;

// const Bullets = styled.ul`
//   width: calc(100% + 40px);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   color: white;
//   font-size: 14px;
//   font-weight: 600;
//   list-style: none;
//   padding: 14px 20px;
//   margin: 0 -20px;
//   background-color: rgba(17, 158, 221, 0.2);
//   z-index: 1;

//   li {
//     display: flex;
//     align-items: center;
//     margin-top: 10px;

//     svg {
//       fill: white;
//       height: 14px;
//       margin-right: 10px;
//       margin-top: 4px;
//     }
//   }

//   @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     font-size: 16px;
//     font-style: normal;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     margin: 0;

//     li {
//       margin-top: 0;
//       margin-right: 15px;
//     }
//   }

//   @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
//     li {
//       margin-right: 50px;
//     }
//   }
// `;

const RightArrow = styled(RightArrowIcon)`
  width: 16px;
  margin-top: 2px;
  margin-left: 10px;

  path {
    fill: white;
  }
`;

type Props = {
  data: Pick<
    HeroSection,
    'title' | 'description' | 'imageUrl' | 'smallNote' | 'mobileImageUrl'
  >;
  authenticated?: boolean;
};

export const HomePageHero = (props: Props) => {
  const lang = useContext(LanguageContext);
  const theme = useTheme();
  const isMobile = useMedia(`(max-width: ${theme.viewports.tablet}px)`);
  const [currentUser, loading] = useContext(UserContext);
  const [bgImage, setBgImage] = useState(null);

  useEffect(() => {
    setBgImage(isMobile && mobileImageUrl ? mobileImageUrl : imageUrl);
  }, [isMobile]);

  const {
    authenticated,
    data: { title, description, imageUrl, smallNote, mobileImageUrl },
  } = props;

  const showRegistration = !authenticated || (!currentUser && !loading);

  const actions = [];
  if (showRegistration) {
    actions.push(
      <Button
        key="0"
        appearance="blue"
        href={`/${lang}/register/account`}
        rounded
      >
        <Trans id="homepage.sections.register_btn" />
      </Button>
    );
  } else {
    if (!currentUser?.isPremium && currentUser?.trial) {
      actions.push(
        <Button
          key="0"
          appearance="blue"
          href={`/${lang}/get-started/trial`}
          rounded
        >
          <Trans id="metadata.start_trial_btn" />
        </Button>
      );
    } else {
      actions.push(
        <Button key="0" appearance="blue" href={`/${lang}/pricing`} rounded>
          <Trans id="homepage.hero_section.pricing_btn" />
        </Button>
      );
    }
  }

  actions.push(
    <Button
      key="1"
      href="/se/friskvardsbidrag"
      appearance="white"
      outline
      rounded
    >
      <Trans id="homepage.hero_section.wellness_btn" /> <RightArrow />
    </Button>
  );

  return (
    <Section id="hero" bg={bgImage}>
      <Content>
        <Disclaimer>{smallNote}</Disclaimer>
        <Title className="!text-white !mb-0">{title}</Title>
        <SubTitle>{description}</SubTitle>
        <Actions>{actions}</Actions>
      </Content>
    </Section>
  );
};
